'use client';

import Script from 'next/script';

import { RootError } from '@/components/RootError';
import { env } from '@/environment';
import { MetaTracer } from '@/infra/logs/MetaTracer';

export default function GlobalError({ error, reset }: { error: { digest?: string } & Error; reset: () => void }) {
  return (
    <html lang="en">
      <head>
        <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" name="viewport" />
        <MetaTracer />
        <link href="https://stats.g.doubleclick.net" rel="preconnect" />
        <link href="https://www.googletagmanager.com" rel="preconnect" />
        <link href="https://www.google-analytics.com" rel="preconnect" />
        <link href={`${env.ASSET_PREFIX}/fonts/fonts.css`} rel="stylesheet" type="text/css" />
      </head>

      <body id="root">
        <Script async src="https://a40.usablenet.com/pt/c/virginvoyages/cookiestorestart" />

        <RootError error={error} reset={reset} />
      </body>
    </html>
  );
}
