import { context, isSpanContextValid, trace } from '@opentelemetry/api';

export const getOTelTraceId = () => {
  const span = trace.getSpan(context.active());
  if (!span) {
    return '';
  }

  const spanContext = span.spanContext();
  if (!spanContext) {
    return '';
  }

  if (!isSpanContextValid(spanContext)) {
    return '';
  }

  return spanContext.traceId;
};
